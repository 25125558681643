<template>
  <div class="page-container-table">
    <!--Phần header của bảng -->
    <button-all-header
      :hideDelete="false"
      :hideImportFile="false"
      :hideDowload="false"
      :hideAdd="false"
      class="custtom-header pr-1"
      @clickExportExcel="dowloadFile"
      @search="search($event)"
    />
    <!--Phần header của bảng -->
    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: false,
      }"
      :sort-options="{
        enabled: false,
      }"
    />

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />
  </div>
</template>

<script>
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import store from '../../store'
import MyPagination from '@/components/pagination/MyPagination.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
  },
  data() {
    return {
      // ==dữ liệu bảng

      dataList: [],
      columns: [
        {
          label: 'LOẠI BỆNH NGHỀ NGHIỆP',
          field: 'name',
        },
        {
          label: 'SỐ NGƯỜI MẮC BỆNH HIỂM NGHÈO',
          field: 'value',

        },
      ],
      // ==phân trang
      urlQuery: {
        search: '',
        pageSize: 10,
        pageNumber: 1,
      },
      totalRecord: 0,
      currentPage: 10,
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {
    async dowloadFile() {
      const name = 'BenhNgheNghiepMoi.xlsx'
      await store.downloadExportFile(name, ConstantsApi.DOWLOAD_EXCEL)
    },
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_DISEASE_NEW, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        this.fetchData(this.urlQuery)
      }
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },
  },
}
</script>

<style>

</style>
